export const multipleProductsText = ({ hasFocusProducts, hasSspProducts, hasRrpProducts }) => {
  // transform text based on license
  const activeSubscriptions = []
  hasSspProducts && activeSubscriptions.push('SSP')
  hasFocusProducts && activeSubscriptions.push('ILS')
  hasRrpProducts && activeSubscriptions.push('RRP')

  /* Add the word 'the' to the subscription names (except for RRP) and then join with the word 'or' */
  const separatedByOr = activeSubscriptions
    .map((sub) => (sub !== 'RRP' ? 'the ' + sub : sub))
    .join(' or ')

  const separatedBySlash = activeSubscriptions.join('/')

  return { separatedByOr, separatedBySlash }
}
