import React, { useEffect } from 'react'
import { Box, Button, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import Question from './Question'

export default function TitrationQuestions({
  complexity,
  score,
  setHidePrintButton,
  questions,
  onPrevious,
  onNext,
  onScoreSelection,
}) {
  const { title, caption, colour } = complexity
  const allQuestionsAnswered = Object.values(score).every((x) => !!x)

  useEffect(() => {
    setHidePrintButton(true)
  }, [])
  return (
    <>
      {questions.map((question, index) => {
        const questionProps = {
          score,
          onScoreSelection,
          ...question,
        }
        return (
          <div id={question.id} key={question.id}>
            <Box key={`titration-question-${index}`}>
              {question.title && (
                <CardHeader
                  title={question.title}
                  titleTypographyProps={{ align: 'center', variant: 'h4', color: 'primary' }}
                />
              )}
              <CardContent>
                <Question {...questionProps} />
              </CardContent>
            </Box>
          </div>
        )
      })}
      <Stack direction="row" justifyContent="space-between" m={4}>
        <Button variant="contained" onClick={onPrevious} sx={{ alignSelf: 'end' }}>
          Previous
        </Button>
        {allQuestionsAnswered && (
          <Box id="view-titration-recommendation" backgroundColor="#eef7f4" p={2} maxWidth={325}>
            <Typography variant="h5" color={colour}>
              {title}
            </Typography>
            <Typography mb={2} variant="body1">
              {caption}
            </Typography>
            <Button variant="contained" onClick={onNext}>
              <Typography variant="button">View Titration Recommendations</Typography>
            </Button>
          </Box>
        )}
      </Stack>
    </>
  )
}
