/**
 * every object is a slide
 */
import TitrationQuestions from 'components/resources/survey/TitrationQuestions'
import Information from 'components/resources/survey/Information'
import Recommendations from 'components/resources/survey/Recommendations'
import React from 'react'
export const titrationToolSurvey = [
  {
    page: 0,
    type: 'intro',
    title: 'Introduction to the SSP Program Planning Tool',
    videoURL: '//player.vimeo.com/video/1002009378',
    videoTitle: 'Introduction to the Titration Tool',
    content: [
      <span>
        This interactive tool will help guide you through holistically considering how factors like
        client presentation, autonomic patterns, and access to support and resources influence
        planning for and delivering the SSP. These factors are informed by both your intake process
        and ongoing relationship with the client.
      </span>,
      <span style={{ fontStyle: 'italic' }}>
        Please remember this tool is only a guide, designed to help you think through some of the
        important factors to consider when preparing for SSP Delivery.
      </span>,
    ],
    Component: ({ ...props }) => <Information {...props} />,
  },
  {
    page: 1,
    type: 'questions',
    Component: ({ ...props }) => <TitrationQuestions {...props} />,
  },
  {
    page: 2,
    type: 'recommendations',
    title: 'Titration Recommendations',
    Component: ({ ...props }) => <Recommendations {...props} />,
  },
]

export const QUESTIONS = [
  {
    id: 'question1',
    scrollTo: 'question2',
    title: 'CLIENT PRESENTATION',
    question: "How is your client's overall health?",
    high: 'Poor',
    low: 'Well',
    description:
      "Consider your client's health history and current status, including conditions, symptoms, recent procedures, and other challenges, and how well these are managed. Be especially considerate of the brain-gut connection and features of the social engagement system.",
    tooltip:
      'In addition to skilled observation and interview with your client, you may use results from standardized assessments to inform your understanding. If your client presents with symptoms of anxiety, depression, or post-traumatic stress, you may consider using the PHQ-9, GAD-7, or PCL-5. If your child client presents with emotional, social, developmental or motor differences, consider using the PSC or the DCDQ.',
  },
  {
    id: 'question2',
    scrollTo: 'question3',
    question: 'What influence do trauma or difficult life events have on your client?',
    high: 'Very influential',
    low: 'Not very influential',
    description:
      "Consider your client's personal history, adverse childhood experiences, and any reported, observed, or expected stressors. Not all trauma is known, or able to be reported. Remember that trauma is not the event itself, but how the nervous system responds to adversity.",
    tooltip:
      'You may use the Adverse Childhood Experiences (ACE) questionnaire to help in your understanding.',
  },
  {
    id: 'question3',
    scrollTo: 'question4',
    question: 'What sensory processing differences does your client present with?',
    high: 'Significant sensory differences',
    low: 'No significant sensory differences',
    description:
      'Consider sensitivities, responsiveness, adaptations and overall sensory health. If you are not trained to evaluate or provide care for sensory processing differences, you may seek mentorship, consultation, or refer to a professional with this scope of practice.',
    tooltip:
      'You may use the Brain Body Center Sensory Scales (BBCSS) to better understand your client’s sensory processing.',
  },
  {
    id: 'question4',
    scrollTo: 'question5',
    title: 'AUTONOMIC PATTERNS',
    question: "How is your client's regulatory capacity?",
    high: 'Poor regulatory capacity',
    low: 'Adaptive regulatory capacity',
    description:
      "Remember that regulation does not mean being in a ventral or 'calm' state all the time, rather the ability to flexibly respond to life's challenges by moving between states. Consider your client's ability to self-regulate and co-regulate in a helpful, positive way.",
    tooltip:
      'To inform your understanding, you may consider using the Body Perception Questionnaire (BPQ), a measure of autonomic reactivity.',
  },
  {
    id: 'question5',
    scrollTo: 'question6',
    title: 'SUPPORT & RESOURCES',
    question: "What is the strength and stability of your client's social support?",
    high: 'Weak or unstable social support',
    low: 'Strong and stable social support',
    description:
      'Consider the household, family, friends and community, in addition to your own relationship with the client. Does your client have access to safe relationships, and are they able to communicate their relational needs?',
    tooltip: '',
  },
  {
    id: 'question6',
    scrollTo: 'view-titration-recommendation',
    question: 'What access does your client have to external resources?',
    high: 'Limited Resources',
    low: 'Abundant resources',
    description:
      'Consider hierarchical needs including basic needs for shelter, nutrition, and physical safety, in addition to access to medical care, financial stability, work and education. Does your client have access to a safe environment?',
    tooltip: '',
  },
]

export const RATING = {
  low: 'Low',
  medium: 'Moderate',
  high: 'High',
}

export const TITRATION_RECOMMENDATIONS_TABLES = [
  {
    header: 'Provider Readiness',
    body: {
      [RATING.low]: [
        { cellCategory: 'Training', cellValue: 'Certified, Trauma-informed' },
        { cellCategory: 'Experience', cellValue: 'New to program delivery' },
        { cellCategory: 'Relationship', cellValue: 'New client relationship' },
        {
          cellCategory: 'Consultation',
          cellValue: 'Consultation with healthcare team is not likely needed',
        },
        { cellCategory: 'Mentorship', cellValue: 'Consider Mentorship or referral as needed' },
      ],
      [RATING.medium]: [
        {
          cellCategory: 'Training',
          cellValue: 'Certified, Trauma-informed, with Professional Training',
        },
        { cellCategory: 'Experience', cellValue: 'Some experience with presenting challenges' },
        { cellCategory: 'Relationship', cellValue: 'Developing client relationship' },
        { cellCategory: 'Consultation', cellValue: 'Consider consulting with healthcare team' },
        { cellCategory: 'Mentorship', cellValue: 'Consider Mentorship or referral as needed' },
      ],
      [RATING.high]: [
        {
          cellCategory: 'Training',
          cellValue: 'Certified, Trauma-informed, with Clinical Training',
        },
        {
          cellCategory: 'Experience',
          cellValue: 'Significant experience with presenting challenges',
        },
        { cellCategory: 'Relationship', cellValue: 'Established client relationship' },
        { cellCategory: 'Consultation', cellValue: 'Consult with healthcare team' },
        { cellCategory: 'Mentorship', cellValue: 'Consider Mentorship or referral as needed' },
      ],
    },
  },
  {
    header: 'Program Planning',
    body: {
      [RATING.low]: [
        { cellCategory: 'Pathway', cellValue: 'Start with Core' },
        { cellCategory: 'Playlist', cellValue: 'Client preference' },
        {
          cellCategory: 'Delivery Model',
          cellValue: 'In-person or remote delivery (May consider independent listening)',
        },
        {
          cellCategory: 'Frequency',
          cellValue: 'Clients may complete a program in 5 - 10 days or longer',
        },
        { cellCategory: 'Duration', cellValue: 'Start with 10 - 15 minutes' },
      ],
      [RATING.medium]: [
        { cellCategory: 'Pathway', cellValue: 'Start with Connect or Core' },
        {
          cellCategory: 'Playlist',
          cellValue: 'Client preference, consider influence of lyrical content',
        },
        {
          cellCategory: 'Delivery Model',
          cellValue: 'In-person or remote delivery (May consider independent listening)',
        },
        {
          cellCategory: 'Frequency',
          cellValue: 'Clients may complete a program in 10 - 30 days or longer',
        },
        { cellCategory: 'Duration', cellValue: 'Start with 5 - 10 minutes' },
      ],
      [RATING.high]: [
        { cellCategory: 'Pathway', cellValue: 'Start with Connect' },
        {
          cellCategory: 'Playlist',
          cellValue: 'Client preference, consider influence of lyrical content',
        },
        {
          cellCategory: 'Delivery Model',
          cellValue: 'In-person or remote delivery (Independent listening is not recommended)',
        },
        {
          cellCategory: 'Frequency',
          cellValue: 'Clients may complete a program in 30 - 60 days or longer',
        },
        { cellCategory: 'Duration', cellValue: 'Start with 1 - 5 minutes' },
      ],
    },
  },
]
