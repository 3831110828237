/**
 * Note:
 * - only one card can be shown at once for each subscription (ie 1 ssp card, 1 ils card)
 * - This may need to be refactored again to account for cases with multiple providers
 */

import {
  SubscriptionPastDue,
  SubscriptionUnpaid,
  VoiceProTrainingComplete,
  VoiceProTrainingIncomplete,
} from '../components/cards'
import NoProducts from '../components/cards/NoProducts'
import RecentAssessmentsDetailsCard from '../components/cards/RecentAssessmentsDetailsCard'
import {
  ClientWelcomeCard,
  ProviderWelcomeCard,
  ProviderWelcomeCardWithWelcomeMessage,
} from '../components/cards/WelcomeCards'
import { ClientGettingStarted, ProviderGettingStarted } from '../components/cards/GettingStarted'
import IncompleteTrainingCard from '../components/cards/IncompleteTrainingCard'
import CompleteTrainingCard from '../components/cards/CompleteTrainingCard'
import SSPHeadPhoneGuidelines from '../components/cards/SSPHeadphoneGuidelines'
import NewActionCard from '../components/cards/NewActionCard'
import SessionsChart from '../client/SessionsChart'
import GettingStartedWithAssessments from '../components/cards/gettingStartedWithAssessments'
import FocusHeadphoneGuidelines from '../components/cards/FocusHeadphoneGuidelines'
import ContinueWithAssessments from '../components/cards/ContinueWithAssessments'
import ProcessingCard from '../components/cards/ProcessingCard'

/**
 * The list here is exclusive - use find here
 * Notes:
 * - test with `isActive:()=>true`
 */

const NullCard = () => {
  return null
}

// PROVIDERS - THIS IS CURRENT LOGGED IN USER CARDS
export const PROVIDER_SSP_CARDS = [
  {
    Card: SubscriptionUnpaid,
    priority: 1,
    isActive: ({
      hasCompletedSspCertification,
      hasUnpaidSspSubscription,
      isBilling,
      hasOrgSspCertificationCompleted,
    }) =>
      (hasCompletedSspCertification || (isBilling && hasOrgSspCertificationCompleted)) &&
      hasUnpaidSspSubscription,
  },
  {
    Card: SubscriptionPastDue,
    priority: 1.2,
    isActive: ({
      hasPastDueSspSubscription,
      hasCompletedSspCertification,
      isBilling,
      hasOrgSspCertificationCompleted,
    }) =>
      (hasCompletedSspCertification || (isBilling && hasOrgSspCertificationCompleted)) &&
      hasPastDueSspSubscription,
  },
  {
    Card: NullCard,
    priority: 1.2,
    isActive: ({
      hasSspProducts,
      hasSspSessions,
      hasCompletedSspCertification,
      sspInTrainingOnly,
    }) => {
      return hasCompletedSspCertification && hasSspProducts && !hasSspSessions && !sspInTrainingOnly
    },
  },
  {
    Card: CompleteTrainingCard,
    priority: 2,
    type: 'SSP',
    isActive: ({
      hasCompletedSspCertification,
      hasPaidSspSubscription,
      sspInTrainingOnly,
      hasUnpaidSspSubscription,
      hasPastDueSspSubscription,
    }) =>
      hasCompletedSspCertification &&
      (!hasPaidSspSubscription || sspInTrainingOnly) &&
      !hasPastDueSspSubscription &&
      !hasUnpaidSspSubscription,
  },
  {
    Card: IncompleteTrainingCard,
    priority: 2,
    isActive: ({ hasSspCertification, hasCompletedSspCertification }) => {
      return !hasCompletedSspCertification && hasSspCertification
    },
  },
]

export const PROVIDER_RRP_CARDS = [
  {
    Card: SubscriptionUnpaid,
    priority: 1,
    isActive: ({
      hasCompletedRrpCertification,
      hasUnpaidRrpSubscription,
      isBilling,
      hasOrgRrpCertificationCompleted,
    }) =>
      (hasCompletedRrpCertification || (isBilling && hasOrgRrpCertificationCompleted)) &&
      hasUnpaidRrpSubscription,
  },
  {
    Card: SubscriptionPastDue,
    priority: 1.2,
    isActive: ({
      hasPastDueRrpSubscription,
      hasCompletedRrpCertification,
      isBilling,
      hasOrgRrpCertificationCompleted,
    }) =>
      (hasCompletedRrpCertification || (isBilling && hasOrgRrpCertificationCompleted)) &&
      hasPastDueRrpSubscription,
  },
  {
    Card: NullCard,
    priority: 1.2,
    isActive: ({
      hasRrpProducts,
      hasRrpSessions,
      hasCompletedRrpCertification,
      rrpInTrainingOnly,
    }) => {
      return hasCompletedRrpCertification && hasRrpProducts && !hasRrpSessions && !rrpInTrainingOnly
    },
  },
  {
    Card: CompleteTrainingCard,
    priority: 2,
    type: 'RRP',
    isActive: ({
      hasCompletedRrpCertification,
      hasPaidRrpSubscription,
      rrpInTrainingOnly,
      hasUnpaidRrpSubscription,
      hasPastDueRrpSubscription,
    }) =>
      hasCompletedRrpCertification &&
      (!hasPaidRrpSubscription || rrpInTrainingOnly) &&
      !hasPastDueRrpSubscription &&
      !hasUnpaidRrpSubscription,
  },
  {
    Card: IncompleteTrainingCard,
    priority: 2,
    isActive: ({ hasRrpCertification, hasCompletedRrpCertification }) => {
      return !hasCompletedRrpCertification && hasRrpCertification
    },
  },
]

// SSP BILLING PROVIDERS (if there are more than 1 provider in organization)
export const BILLING_PROVIDER_SSP_CARDS = [
  {
    Card: NullCard,
    priority: 1,
    isActive: ({
      hasSspProducts,
      hasSspSessions,
      hasCompletedSspCertification,
      sspInTrainingOnly,
    }) => {
      return hasCompletedSspCertification && hasSspProducts && !hasSspSessions && !sspInTrainingOnly
    },
  },
  {
    Card: CompleteTrainingCard,
    type: 'SSP',
    priority: 2,
    isActive: ({ hasAllCanceledSspSubscription }) => hasAllCanceledSspSubscription,
  },
  {
    Card: CompleteTrainingCard,
    type: 'SSP',
    priority: 3,
    isActive: ({
      hasCompletedSspCertification,
      hasOrgSspCertificationCompleted,
      hasPaidSspSubscription,
      hasPastDueSspSubscription,
      hasUnpaidSspSubscription,
      numberOfProviders,
    }) =>
      numberOfProviders > 1 &&
      (hasCompletedSspCertification || hasOrgSspCertificationCompleted) &&
      !hasPaidSspSubscription &&
      !hasPastDueSspSubscription &&
      !hasUnpaidSspSubscription,
  },
]

// RRP BILLING PROVIDERS (if there are more than 1 provider in organization)
export const BILLING_PROVIDER_RRP_CARDS = [
  {
    Card: NullCard,
    priority: 1,
    isActive: ({
      hasRrpProducts,
      hasRrpSessions,
      hasCompletedRrpCertification,
      rrpInTrainingOnly,
    }) => {
      return hasCompletedRrpCertification && hasRrpProducts && !hasRrpSessions && !rrpInTrainingOnly
    },
  },
  {
    Card: CompleteTrainingCard,
    type: 'RRP',
    priority: 2,
    isActive: ({ hasAllCanceledRrpSubscription }) => hasAllCanceledRrpSubscription,
  },
  {
    Card: CompleteTrainingCard,
    type: 'RRP',
    priority: 3,
    isActive: ({
      hasCompletedRrpCertification,
      hasOrgRrpCertificationCompleted,
      hasPaidRrpSubscription,
      hasPastDueRrpSubscription,
      hasUnpaidRrpSubscription,
      numberOfProviders,
    }) =>
      numberOfProviders > 1 &&
      (hasCompletedRrpCertification || hasOrgRrpCertificationCompleted) &&
      !hasPaidRrpSubscription &&
      !hasPastDueRrpSubscription &&
      !hasUnpaidRrpSubscription,
  },
]

export const BILLING_PROVIDER_FOCUS_CARDS = [
  {
    Card: NullCard,
    priority: 1.2,
    isActive: ({
      hasFocusProducts,
      hasCompletedFocusCertification,
      hasFocusSessions,
      focusInTrainingOnly,
    }) => {
      return (
        hasFocusProducts &&
        !hasFocusSessions &&
        hasCompletedFocusCertification &&
        !focusInTrainingOnly
      )
    },
  },
  {
    // show this card when we are cancelled
    Card: CompleteTrainingCard,
    priority: 2,
    type: 'ILS',
    isActive: ({ hasAllCanceledFocusSubscription }) => hasAllCanceledFocusSubscription,
  },
  {
    // show this card when we have multi providers in org and someone (not us has completed certification)
    Card: CompleteTrainingCard,
    priority: 3,
    type: 'ILS',
    isActive: ({
      hasCompletedFocusCertification,
      hasOrgFocusCertificationCompleted,
      hasPaidFocusSubscription,
      hasPastDueFocusSubscription,
      hasUnpaidFocusSubscription,
      numberOfProviders,
    }) =>
      numberOfProviders > 1 &&
      (hasCompletedFocusCertification || hasOrgFocusCertificationCompleted) &&
      !hasPaidFocusSubscription &&
      !hasPastDueFocusSubscription &&
      !hasUnpaidFocusSubscription,
  },
]

export const PROVIDER_FOCUS_CARDS = [
  {
    Card: SubscriptionUnpaid,
    priority: 1,
    isActive: ({
      isBilling,
      hasOrgFocusCertificationCompleted,
      hasUnpaidFocusSubscription,
      hasCompletedFocusCertification,
    }) =>
      (hasCompletedFocusCertification || (isBilling && hasOrgFocusCertificationCompleted)) &&
      hasUnpaidFocusSubscription,
  },
  {
    Card: SubscriptionPastDue,
    priority: 1.1,
    isActive: ({
      hasPastDueFocusSubscription,
      hasCompletedFocusCertification,
      isBilling,
      hasOrgFocusCertificationCompleted,
    }) =>
      (hasCompletedFocusCertification || (isBilling && hasOrgFocusCertificationCompleted)) &&
      hasPastDueFocusSubscription,
  },
  {
    Card: NullCard,
    priority: 1.2,
    isActive: ({
      hasFocusProducts,
      hasCompletedFocusCertification,
      hasFocusSessions,
      focusInTrainingOnly,
    }) => {
      return (
        hasFocusProducts &&
        !hasFocusSessions &&
        hasCompletedFocusCertification &&
        !focusInTrainingOnly
      )
    },
  },
  {
    Card: CompleteTrainingCard,
    priority: 2,
    type: 'ILS',
    isActive: ({
      hasCompletedFocusCertification,
      hasPaidFocusSubscription,
      focusInTrainingOnly,
      hasPastDueFocusSubscription,
      hasUnpaidFocusSubscription,
    }) =>
      (!hasPaidFocusSubscription || focusInTrainingOnly) &&
      hasCompletedFocusCertification &&
      !hasPastDueFocusSubscription &&
      !hasUnpaidFocusSubscription,
  },
  {
    Card: IncompleteTrainingCard,
    priority: 2,
    isActive: ({ hasFocusCertification, hasCompletedFocusCertification }) =>
      !hasCompletedFocusCertification && hasFocusCertification,
  },
]

/**
 * The list here is inclusive - use Filter here
 */
export const PROVIDER_SSP_FOCUS_RRP_CARDS = [
  {
    Card: ProviderWelcomeCard,
    priority: 0,
    isActive: ({ showUserWelcomeCall }) => !showUserWelcomeCall,
  },
  {
    Card: ProviderWelcomeCardWithWelcomeMessage,
    priority: 0,
    isActive: ({ showUserWelcomeCall }) => showUserWelcomeCall,
  },
  {
    Card: ProviderGettingStarted,
    priority: 1.1,
    type: 'SSP',
    isActive: ({
      hasSspProducts,
      hasSspSessions,
      hasCompletedSspCertification,
      sspInTrainingOnly,
    }) => hasCompletedSspCertification && hasSspProducts && !hasSspSessions && !sspInTrainingOnly,
  },
  {
    Card: ProviderGettingStarted,
    priority: 1.1,
    type: 'RRP',
    isActive: ({
      hasRrpProducts,
      hasRrpSessions,
      hasCompletedRrpCertification,
      rrpInTrainingOnly,
    }) => {
      return hasCompletedRrpCertification && hasRrpProducts && !hasRrpSessions && !rrpInTrainingOnly
    },
  },
  {
    Card: ProviderGettingStarted,
    priority: 1.1,
    type: 'ILS',
    isActive: ({
      hasFocusProducts,
      hasCompletedFocusCertification,
      hasFocusSessions,
      focusInTrainingOnly,
    }) =>
      hasFocusProducts &&
      !hasFocusSessions &&
      hasCompletedFocusCertification &&
      !focusInTrainingOnly,
  },
  {
    Card: NoProducts,
    priority: 1.1,
    isActive: ({
      hasAllCanceledFocusSubscription,
      hasAllCanceledSspSubscription,
      hasAllCanceledRrpSubscription,
      hasFocusProducts,
      hasSspProducts,
      hasRrpProducts,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
      hasCompletedFocusCertification,
    }) => {
      const hasAnyProducts = hasSspProducts || hasRrpProducts || hasFocusProducts
      return (
        (hasAllCanceledFocusSubscription &&
          hasAllCanceledSspSubscription &&
          hasAllCanceledRrpSubscription) ||
        // don't show when we have at least 1 active subscription
        (hasAllCanceledFocusSubscription && !hasAnyProducts && hasCompletedFocusCertification) ||
        (hasAllCanceledRrpSubscription && !hasAnyProducts && hasCompletedRrpCertification) ||
        (hasAllCanceledSspSubscription && !hasAnyProducts && hasCompletedSspCertification)
      )
    },
  },
  {
    Card: RecentAssessmentsDetailsCard,
    priority: 2,
    isActive: ({
      hasSspProducts,
      hasRrpProducts,
      hasFocusProducts,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
      hasCompletedFocusCertification,
      hasRecentAssessments,
    }) =>
      hasRecentAssessments &&
      ((hasCompletedSspCertification && hasSspProducts) ||
        (hasCompletedRrpCertification && hasRrpProducts) ||
        (hasCompletedFocusCertification && hasFocusProducts)),
  },
  {
    Card: GettingStartedWithAssessments,
    priority: 2,
    isActive: ({
      hasSspProducts,
      hasRrpProducts,
      hasFocusProducts,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
      hasCompletedFocusCertification,
      hasRecentAssessments,
      hasAssessmentProducts,
      hasAssessmentSessions,
      sspInTrainingOnly,
      rrpInTrainingOnly,
      focusInTrainingOnly,
    }) =>
      !hasAssessmentSessions &&
      !hasRecentAssessments &&
      hasAssessmentProducts &&
      ((hasCompletedSspCertification && hasSspProducts && !sspInTrainingOnly) ||
        (hasCompletedRrpCertification && hasRrpProducts && !rrpInTrainingOnly) ||
        (hasCompletedFocusCertification && hasFocusProducts && !focusInTrainingOnly)),
  },
  {
    Card: ContinueWithAssessments,
    priority: 2,
    isActive: ({
      hasSspProducts,
      hasRrpProducts,
      hasFocusProducts,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
      hasCompletedFocusCertification,
      hasRecentAssessments,
      hasAssessmentProducts,
      hasAssessmentSessions,
    }) =>
      hasAssessmentSessions &&
      !hasRecentAssessments &&
      hasAssessmentProducts &&
      ((hasCompletedSspCertification && hasSspProducts) ||
        (hasCompletedRrpCertification && hasRrpProducts) ||
        (hasCompletedFocusCertification && hasFocusProducts)),
  },
  {
    Card: FocusHeadphoneGuidelines,
    priority: 3,
    isActive: ({
      hasFocusProducts,
      hasFocusSessions,
      hasCompletedFocusCertification,
      focusInTrainingOnly,
    }) =>
      hasCompletedFocusCertification &&
      hasFocusProducts &&
      !hasFocusSessions &&
      !focusInTrainingOnly,
  },
  {
    Card: ProcessingCard,
    priority: 3,
    isActive: ({ isProcessingPayment }) => isProcessingPayment,
  },
]

export const PROVIDER_VOICE_PRO_CARDS = [
  {
    Card: VoiceProTrainingIncomplete,
    priority: 1,
    isActive: ({ hasVoiceProCertification, hasCompletedVoiceProCertification }) =>
      hasVoiceProCertification && !hasCompletedVoiceProCertification,
  },
  {
    Card: VoiceProTrainingComplete,
    priority: 1,
    isActive: ({ hasCompletedVoiceProCertification }) => hasCompletedVoiceProCertification,
  },
]

// CLIENTS
export const CLIENT_CARDS = [
  {
    Card: ClientWelcomeCard,
    isActive: () => true,
  },
  {
    Card: NoProducts,
    isActive: ({ products }) =>
      !products?.filter(({ category }) => category !== 'assessment')?.length,
  },
  {
    Card: ClientGettingStarted,
    priority: 1,
    type: 'SSP',
    isActive: ({ hasSspProducts, hasSspSessions }) => hasSspProducts && !hasSspSessions,
  },
  {
    Card: ClientGettingStarted,
    priority: 1,
    type: 'RRP',
    isActive: ({ hasRrpProducts, hasRrpSessions }) => hasRrpProducts && !hasRrpSessions,
  },
  {
    Card: ClientGettingStarted,
    priority: 1,
    type: 'ILS',
    isActive: ({ hasFocusProducts, hasFocusSessions }) => hasFocusProducts && !hasFocusSessions,
  },
  {
    Card: NewActionCard,
    isActive: ({ sentAssessments, hasAssessmentProducts }) =>
      hasAssessmentProducts && sentAssessments?.length,
  },
  {
    Card: SessionsChart,
    gridProps: { xs: 12 },
    isActive: ({ hasSspSessions, hasRrpSessions, hasFocusSessions }) =>
      hasSspSessions || hasFocusSessions || hasRrpSessions,
  },
  {
    Card: SSPHeadPhoneGuidelines,
    isActive: ({ hasSspProducts, hasRrpProducts, hasSspSessions, hasRrpSessions }) =>
      (hasSspProducts && !hasSspSessions) || (hasRrpProducts && !hasRrpSessions),
  },
]
