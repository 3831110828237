/**
 * Note:
 * - removed payment page (it appears to be old legacy code)
 *
 * TODO:
 * - update client component to be a wrapper
 */
import React from 'react'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { store } from 'store'

import { client } from 'utils/apollo'
import MuiThemeProvider from 'utils/theme/Theme'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import Autoreload from 'components/Autoreload'
import ErrorBoundary from 'components/ErrorBoundary'
import Snackbars from 'components/containers/Snackbars'

import { routes } from 'utils/constants/routes'
import ROLES from 'utils/constants/roles'

import SignIn from './views/auth/sign-in/SignIn'
import ForgotPassword from './views/auth/forgot-password/ForgotPassword'
import ResetPassword from './views/auth/reset-password/ResetPassword'
import InvitationAcceptNew from './views/auth/invitations/accept-new/InvitationAcceptNew'
import ChangeEmailVerification from './views/change-email/ChangeEmailVerification'

import Admins from './views/users/admins/Admins'
import Providers from './views/users/providers/Providers'

import Licenses from './views/licenses/Licenses'

import Billing from './views/billing/Billing'
import BillingHistory from './views/billing/BillingHistory'
import ActiveClients from './views/billing/ActiveClients'
import FocusClientLicenses from './views/billing/FocusClientLicenses'
import ClientLicenses from './views/billing/ClientLicenses'
import HowClientLicensesWork from './views/billing/HowClientLicensesWork'

import Assessments from './views/assessments/AssessmentsLayout'
import AssessmentReport from './views/assessments/Reports'
import AssessmentTable from './views/assessments/AssessmentTable'

import Products from './views/products/Products'
import Account from './views/account/Account'
import ProfessionalCredentials from './views/account/ProfessionalCredentials'
import ChangePassword from './views/account/ChangePassword'
import ChangeEmail from './views/account/ChangeEmail'

import ResourceLayout from './views/resources/ResourceLayout'

import NewPurchase from './views/new-purchase/NewPurchase'
import ConfirmPurchase from './views/purchase/ConfirmPurchase'
import ProductsPurchase from './views/purchase/Products'
import ShippingPurchase from './views/purchase/Shipping'
import SuccessPurchase from './views/purchase/Success'
import AccessoriesPurchase from './views/purchase/Accessories'

import NotFound from './views/404'
import MouseFlow from 'components/analytics/MouseFlow'

import { GTMProvider } from 'components/GTMContext'
import Success from 'views/new-purchase/Success'
import CreateAssessment from 'views/assessments/CreateAssessment'
import UpdateAssessment from 'views/assessments/UpdateAssessments'
import ClientUpdateAssessments from 'views/assessments/ClientUpdateAssessments'
import ActiveConnection from 'views/connections/ActiveConnection'
import ConnectionsHistory from 'views/connections/ConnectionsHistory'

import Store from './views/Store'
import GetOrganizationInfo from './components/GetOrganizationInfo'

import { SnackbarProvider } from 'notistack'
import PrivateRouteWrapper from './components/routes/PrivateRouteWrapper'
import Invitations from './views/users/invitations/Invitations'

// custom components for routes v6
import ResourceItems from './components/resources/Items'
import ResourceItemDetails from './components/resources/ItemDetails'

// authentication
import { Authenticator } from '@aws-amplify/ui-react'
import ProvidersTable from './views/users/providers/ProvidersTable'
import AdminsTable from './views/users/admins/AdminsTable'
import StoreFront from './views/purchase/StoreFront'
import StoreLayout from './views/StoreLayout'
import MyAccountWrapper from './views/account/MyAccountWrapper'
import MyProfile from './views/account/MyProfile'
import MyOrganization from './views/account/MyOrganization'
import FocusStoreFront from './views/purchase/FocusStoreFront'
import RRPStoreFront from './views/purchase/RRPStoreFront'
import ClientLicenseStoreFront from './views/purchase/ClientLicensesStoreFront'
import MyAccount from 'views/account/MyAccount'
import DashboardWrapper from 'views/dashboard/DashboardWrapper'
import ProviderDashboard from 'views/dashboard/ProviderDashboard'
import ClientDashboard from 'views/dashboard/ClientDashboard'
import ProviderClientDashboard from 'views/dashboard/ProviderClientDashboard'
import SelectAssessmentPage from 'views/assessments/SelectAssessmentPage'

// academy
import AcademyLayout from 'views/academy/academy-layout'
import OnDemand from 'views/academy/on-demand'
import LiveEvents from 'views/academy/live-events'
import Community from 'views/community/Community'
import CreateNewClient from 'views/users/clients/CreateNewClient'

import { HubSpotWidget } from 'components/header/components/HubSpotWidget'
import ClientsTable2 from 'views/users/clients/ClientsTable2'
import ClientsLayoutWrapper2 from 'views/users/clients/ClientsLayoutWrapper2'
import GeneralClientsView from 'views/users/clients/details/GeneralClientsView'
import ClientDashboardInfo from 'views/users/clients/details/ClientDashboardInfo'
import DemographicInfoClientsView from 'views/users/clients/details/DemographicInfoClientsView'
import { ManageDelivery } from 'views/users/clients/details/manage-delivery'

export default function App() {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <MuiThemeProvider>
            <SnackbarProvider
              sx={{
                '& .SnackbarItem-variantSuccess': { backgroundColor: '#008080' },
                '& .SnackbarItem-variantError': { backgroundColor: '#f50057' },
              }}
              autoHideDuration={20000}
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Snackbars />
                <Autoreload />
                <MouseFlow />

                <ErrorBoundary>
                  <GetOrganizationInfo />
                  <GTMProvider>
                    <Authenticator.Provider>
                      <Routes>
                        {/* Public routes no not need a wrapper..
                         */}
                        <Route path="/sign-in" element={<SignIn />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route
                          path="/forgot-password/:code/:encodedEmail"
                          element={<ResetPassword />}
                        />
                        <Route path="/invitations/accept/:id" element={<InvitationAcceptNew />} />
                        <Route
                          path="/verify-email-change/:id"
                          element={<ChangeEmailVerification />}
                        />
                        <Route path="/new-purchase" element={<NewPurchase />} />
                        <Route path="/new-purchase/success" element={<Success />} />

                        {/* private routes go here */}
                        <Route
                          path="/"
                          element={
                            <PrivateRouteWrapper roles={routes.dashboard.roles}>
                              <DashboardWrapper />
                            </PrivateRouteWrapper>
                          }
                        >
                          <Route
                            index
                            element={
                              <>
                                <ProviderDashboard />
                                <ClientDashboard />
                              </>
                            }
                          />
                          <Route path="/dashboard/:id" element={<ProviderClientDashboard />} />
                        </Route>
                        {/* TODO: sessions go here */}
                        <Route
                          path="/admins"
                          element={
                            <PrivateRouteWrapper roles={routes.admins.roles}>
                              <Admins />
                            </PrivateRouteWrapper>
                          }
                        >
                          <Route index element={<AdminsTable />} />
                          <Route
                            path="pending-invitations"
                            element={<Invitations roles={[ROLES.ADMIN, ROLES.ADMIN_NO_CLIENTS]} />}
                          />
                        </Route>
                        <Route
                          path="/providers"
                          element={
                            <PrivateRouteWrapper roles={routes.admins.roles}>
                              <Providers />
                            </PrivateRouteWrapper>
                          }
                        >
                          <Route index element={<ProvidersTable />} />
                          <Route
                            path="pending-invitations"
                            element={
                              <Invitations roles={[ROLES.PROVIDER, ROLES.PROVIDER_ALL_CLIENTS]} />
                            }
                          />
                        </Route>
                        <Route
                          path="/licenses"
                          element={
                            <PrivateRouteWrapper roles={routes.licenses.roles}>
                              <Licenses />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route path="clients" element={<ClientsLayoutWrapper2 />}>
                          <Route
                            path="pending-invitations"
                            element={<Invitations roles={[ROLES.CLIENT]} />}
                          />
                          <Route index element={<ClientsTable2 />}></Route>
                          <Route path="create-client" element={<CreateNewClient />} />
                          <Route
                            path=":clientId"
                            element={
                              <>
                                <GeneralClientsView />
                                <DemographicInfoClientsView />
                              </>
                            }
                          />
                          <Route path=":clientId/dashboard" element={<ClientDashboardInfo />} />
                          <Route path=":clientId/manage-delivery" element={<ManageDelivery />} />
                        </Route>
                        <Route
                          path="/programs"
                          element={
                            <PrivateRouteWrapper
                              notAuthorized="hasProducts"
                              roles={routes.programs.roles}
                            >
                              <Products />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/assessments"
                          element={
                            <PrivateRouteWrapper
                              notAuthorized="hasAssessmentProducts"
                              roles={routes.assessments.roles}
                            >
                              <Assessments />
                            </PrivateRouteWrapper>
                          }
                        >
                          <Route index element={<AssessmentTable />} />
                          <Route path="select" element={<SelectAssessmentPage />} />
                          <Route path="reports/:userId" element={<AssessmentReport />} />
                          <Route path="reports" element={<AssessmentReport />} />
                          <Route path=":userId" element={<AssessmentTable />} />
                          <Route path="update/:userId/:sessionId" element={<UpdateAssessment />} />
                          <Route
                            path="/assessments/create/:userId/:productEventId"
                            element={<CreateAssessment />}
                          />
                        </Route>
                        <Route
                          path="/assessments/view/:userId/:sessionId"
                          element={
                            <PrivateRouteWrapper
                              disableDeepLink
                              notAuthorized="hasAssessmentProducts"
                              roles={[...ROLES.CLIENT_ROLES]}
                            >
                              <ClientUpdateAssessments />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/connections/active"
                          element={
                            <PrivateRouteWrapper
                              notAuthorized="hasProducts"
                              roles={ROLES.PROVIDER_ROLES}
                            >
                              <ActiveConnection />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/connections/history"
                          element={
                            <PrivateRouteWrapper
                              notAuthorized="hasProducts"
                              roles={ROLES.PROVIDER_ROLES}
                            >
                              <ConnectionsHistory />
                            </PrivateRouteWrapper>
                          }
                        ></Route>
                        <Route
                          path="/purchase/products"
                          element={
                            <PrivateRouteWrapper disableDeepLink roles={[ROLES.BILLING]}>
                              <ProductsPurchase />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/purchase/shipping"
                          element={
                            <PrivateRouteWrapper disableDeepLink roles={[ROLES.BILLING]}>
                              <ShippingPurchase />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/purchase/confirm"
                          element={
                            <PrivateRouteWrapper disableDeepLink roles={[ROLES.BILLING]}>
                              <ConfirmPurchase />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/purchase/success"
                          element={
                            <PrivateRouteWrapper disableDeepLink roles={[ROLES.BILLING]}>
                              <SuccessPurchase />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/purchase/accessories"
                          element={
                            <PrivateRouteWrapper roles={[ROLES.BILLING]}>
                              <AccessoriesPurchase />
                            </PrivateRouteWrapper>
                          }
                        />

                        <Route
                          path="store"
                          element={
                            <PrivateRouteWrapper roles={[...ROLES.PROVIDER_ROLES, ROLES.BILLING]}>
                              <StoreLayout />
                            </PrivateRouteWrapper>
                          }
                        >
                          <Route path="*" index element={<Store />} />
                          <Route path="ssp" element={<StoreFront />} />
                          <Route path="focus" element={<FocusStoreFront />} />
                          <Route path="rrp" element={<RRPStoreFront />} />
                          <Route path="client-licenses" element={<ClientLicenseStoreFront />} />
                        </Route>
                        <Route
                          path="/billing"
                          element={
                            <PrivateRouteWrapper roles={[ROLES.BILLING]}>
                              <Billing />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/billing/history"
                          element={
                            <PrivateRouteWrapper
                              notAuthorized="showBillingHistory"
                              roles={[ROLES.BILLING]}
                            >
                              <BillingHistory />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/billing/active-ssp-clients"
                          element={
                            <PrivateRouteWrapper roles={[ROLES.BILLING]}>
                              <ActiveClients />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/billing/active-focus-clients"
                          element={
                            <PrivateRouteWrapper roles={[ROLES.BILLING]}>
                              <FocusClientLicenses />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/billing/client-licenses"
                          element={
                            <PrivateRouteWrapper roles={[...ROLES.PROVIDER_ROLES, ROLES.BILLING]}>
                              <ClientLicenses />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/billing/how-client-licenses-work"
                          element={
                            <PrivateRouteWrapper roles={[...ROLES.PROVIDER_ROLES]}>
                              <HowClientLicensesWork />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/academy"
                          element={
                            <PrivateRouteWrapper roles={[...ROLES.PROVIDER_ROLES]}>
                              <AcademyLayout />
                            </PrivateRouteWrapper>
                          }
                        >
                          <Route index element={<OnDemand />} />
                          <Route path="live-training" element={<LiveEvents />} />
                        </Route>

                        <Route
                          path="certifications"
                          element={<Navigate to="/academy" replace={true} />}
                        />

                        <Route
                          path="my"
                          element={
                            <PrivateRouteWrapper>
                              <MyAccountWrapper />
                            </PrivateRouteWrapper>
                          }
                        >
                          <Route path="profile" element={<MyProfile />} />
                          <Route path="organization" element={<MyOrganization />} />
                          <Route path="account" element={<MyAccount />} />
                          <Route path="account/change-password" element={<ChangePassword />} />
                          <Route path="account/change-email" element={<ChangeEmail />} />
                          <Route
                            path="account/change-professional-credentials"
                            element={<ProfessionalCredentials />}
                          />

                          <Route path="billing" element={<Billing />} />

                          <Route index element={<MyProfile />} />
                        </Route>

                        <Route
                          path="community"
                          element={
                            <PrivateRouteWrapper>
                              <Community />
                            </PrivateRouteWrapper>
                          }
                        />

                        {/* TODO: delete below after provider profile release */}
                        <Route
                          path="/account"
                          element={
                            <PrivateRouteWrapper>
                              <Account />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/change-password"
                          element={
                            <PrivateRouteWrapper>
                              <ChangePassword />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/change-email"
                          element={
                            <PrivateRouteWrapper roles={[...ROLES.PROVIDER_ROLES, ROLES.BILLING]}>
                              <ChangeEmail />
                            </PrivateRouteWrapper>
                          }
                        />
                        <Route
                          path="/professional-credentials"
                          element={
                            <PrivateRouteWrapper roles={[...ROLES.PROVIDER_ROLES]}>
                              <ProfessionalCredentials />
                            </PrivateRouteWrapper>
                          }
                        />
                        {/* end of delete */}
                        <Route
                          path="/resources"
                          element={
                            <PrivateRouteWrapper
                              roles={[...ROLES.PROVIDER_ROLES, ROLES.BILLING, ROLES.CLIENT]}
                            >
                              <ResourceLayout />
                            </PrivateRouteWrapper>
                          }
                        >
                          <Route path=":tag">
                            <Route path=":title/:id" element={<ResourceItemDetails />} />
                            <Route index element={<ResourceItems />} />
                          </Route>
                          <Route path="open/:title/:id" element={<ResourceItemDetails />} />
                          <Route path=":title/:id" element={<ResourceItemDetails />} />
                          <Route index element={<ResourceItems />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                      <HubSpotWidget />
                    </Authenticator.Provider>
                  </GTMProvider>
                </ErrorBoundary>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  )
}

if (window.Cypress) {
  window.store = store
}
