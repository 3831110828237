import React from 'react'
import { BulletLink, DashboardCard, Typography } from './DashboardCard2'
import EMAILS from 'utils/constants/emails'
import { useOutletContext } from 'react-router'

export default function CompleteTrainingCard({
  type = '',
  hasActiveSspSubscription,
  hasActiveFocusSubscription,
  hasActiveRrpSubscription,
  ...gridProps
}) {
  const {
    focusInTrainingOnly,
    hasOrgFocusCertificationCompleted,
    hasOrgRrpCertificationCompleted,
    hasOrgSspCertificationCompleted,
    isBilling,
    isFocusCompletedUser,
    isRrpCompletedUser,
    isSspCompletedUser,
    sspInTrainingOnly,
  } = useOutletContext()

  const titleMap = {
    SSP: {
      completedUser: `You are SSP certified!`,
      billingOnly: `A Provider in your Organization is SSP Certified!`,
    },
    ILS: {
      completedUser: `You are ILS certified!`,
      billingOnly: `A Provider in your Organization is ILS Certified!`,
    },
    RRP: {
      completedUser: `You have completed your Preliminary RRP Training!`,
      billingOnly: `A Provider in your Organization has completed Preliminary RRP Training`,
    },
  }

  const isCompletedUser =
    (type === 'SSP' && isSspCompletedUser) ||
    (type === 'ILS' && isFocusCompletedUser) ||
    (type === 'RRP' && isRrpCompletedUser)

  const title = isCompletedUser ? titleMap[type].completedUser : titleMap[type].billingOnly

  const hasPaidTypeSubscription =
    (type === 'SSP' && hasActiveSspSubscription) ||
    (type === 'ILS' && hasActiveFocusSubscription) ||
    (type === 'RRP' && hasActiveRrpSubscription)

  const showBillingMessage =
    isBilling &&
    !hasPaidTypeSubscription &&
    ((type === 'SSP' && hasOrgSspCertificationCompleted) ||
      (type === 'SSP' && sspInTrainingOnly) ||
      (type === 'ILS' && hasOrgFocusCertificationCompleted) ||
      (type === 'ILS' && focusInTrainingOnly) ||
      (type === 'RRP' && hasOrgRrpCertificationCompleted))

  const completionMessage =
    type === 'RRP'
      ? 'Congratulations on obtaining your RRP Training'
      : `Congratulations on obtaining your ${type} certification!`

  return (
    <DashboardCard
      {...gridProps}
      key={`${type.toLowerCase()}-certified-providers`}
      title={title}
      dataTestLabel={`dashboard-card-${type}-certified-non-billing`}
    >
      {isCompletedUser && <Typography>{completionMessage}</Typography>}
      {showBillingMessage && (
        <>
          <Typography pt={4}>
            Please purchase an {type} subscription to begin delivering {type} to clients.
          </Typography>
          <BulletLink internalURI={`/store/${type.toLowerCase()}`}>
            for {type} Subscription purchase options.
          </BulletLink>
        </>
      )}
      {!showBillingMessage && (
        <Typography pt={4}>
          For assistance, please contact our Client Success team at{' '}
          <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
            {EMAILS.supportEmail}
          </a>
          .
        </Typography>
      )}
    </DashboardCard>
  )
}
