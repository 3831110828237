import ROLES from 'utils/constants/roles'
import isValidProfessionalCredentials from '../isValidProfessionalCredentials'
import { canAccessCommunity } from 'views/community/utils/canAccessCommunity'

export const menu = [
  {
    key: 'dashboard',
    text: 'Dashboard',
    url: '/',
    roles: ROLES.ALL_ROLES,
  },
  {
    key: 'admins',
    text: 'Admins',
    url: '/admins',
    roles: ROLES.ADMIN_ROLES,
  },
  {
    key: 'providers',
    text: 'Providers',
    url: '/providers',
    roles: ROLES.ADMIN_ROLES,
  },
  {
    key: 'clients',
    text: 'Clients',
    url: '/clients',
    roles: [ROLES.ADMIN, ...ROLES.PROVIDER_ROLES],
    isEnabled: ({
      hasSspProducts,
      hasCompletedSspCertification,
      hasCompletedFocusCertification,
      professionalCredentials,
      otherProfessionalCredentials,
      hasFocusProducts,
      sspInTrainingOnly,
      focusInTrainingOnly,
      hasRrpProducts,
      hasCompletedRrpCertification,
    }) =>
      (isValidProfessionalCredentials({ professionalCredentials, otherProfessionalCredentials }) &&
        hasCompletedSspCertification &&
        !sspInTrainingOnly &&
        hasSspProducts) ||
      (hasFocusProducts && !focusInTrainingOnly && hasCompletedFocusCertification) ||
      (hasRrpProducts && hasCompletedRrpCertification),
  },
  {
    key: 'licenses',
    text: 'Licenses',
    url: '/licenses',
    roles: ROLES.ADMIN_ROLES,
  },
  {
    key: 'assessments',
    text: 'Assessments',
    url: '/assessments',
    roles: [ROLES.ADMIN, ...ROLES.PROVIDER_ROLES],
    isEnabled: ({
      hasSspProducts,
      hasCompletedSspCertification,
      hasAssessmentProducts,
      professionalCredentials,
      otherProfessionalCredentials,
      hasCompletedFocusCertification,
      hasFocusProducts,
      sspInTrainingOnly,
      focusInTrainingOnly,
      hasRrpProducts,
      hasCompletedRrpCertification,
    }) =>
      isValidProfessionalCredentials({ professionalCredentials, otherProfessionalCredentials }) &&
      hasAssessmentProducts &&
      ((hasCompletedSspCertification && hasSspProducts && !sspInTrainingOnly) ||
        (hasCompletedFocusCertification && hasFocusProducts && !focusInTrainingOnly) ||
        (hasRrpProducts && hasCompletedRrpCertification)),
  },
  {
    key: 'programs',
    text: 'Programs',
    url: '/programs',
    roles: ROLES.ALL_ROLES,
    isEnabled: ({
      hasCompletedSspCertification,
      hasCompletedFocusCertification,
      hasSspProducts,
      isClient,
      professionalCredentials,
      otherProfessionalCredentials,
      hasFocusProducts,
      sspInTrainingOnly,
      focusInTrainingOnly,
      hasRrpProducts,
      hasCompletedRrpCertification,
    }) =>
      (isValidProfessionalCredentials({ professionalCredentials, otherProfessionalCredentials }) &&
        ((hasCompletedSspCertification && hasSspProducts && !sspInTrainingOnly) ||
          (hasCompletedFocusCertification && hasFocusProducts && !focusInTrainingOnly) ||
          (hasRrpProducts && hasCompletedRrpCertification))) ||
      (isClient && (hasSspProducts || hasFocusProducts || hasRrpProducts)),
  },
  // do not show resources to providers with `other` professional credentials
  {
    key: 'resources',
    text: 'Resources',
    url: '/resources',
    roles: [...ROLES.PROVIDER_ROLES, ROLES.CLIENT],
    isEnabled: ({
      hasSspCertification,
      hasRrpCertification,
      hasFocusCertification,
      isClient,
      hasVoiceProCertification,
      isProvider,
      professionalCredentials,
      otherProfessionalCredentials,
      hasSspProducts,
      hasFocusProducts,
      hasRrpProducts,
    }) =>
      (isProvider &&
        isValidProfessionalCredentials({
          professionalCredentials,
          otherProfessionalCredentials,
        }) &&
        (hasSspCertification ||
          hasRrpCertification ||
          hasFocusCertification ||
          hasVoiceProCertification)) ||
      (isClient && (hasSspProducts || hasFocusProducts || hasRrpProducts)),
  },
  {
    key: 'academy',
    text: 'Academy',
    url: '/academy',
    roles: ROLES.PROVIDER_ROLES,
    isEnabled: ({
      hasSspCertification,
      hasRrpCertification,
      hasFocusCertification,
      hasVoiceProCertification,
      isProvider,
      professionalCredentials,
      otherProfessionalCredentials,
    }) =>
      isProvider &&
      isValidProfessionalCredentials({
        professionalCredentials,
        otherProfessionalCredentials,
      }) &&
      (hasSspCertification ||
        hasRrpCertification ||
        hasFocusCertification ||
        hasVoiceProCertification),
  },
  {
    key: 'connections',
    text: 'Connections',
    url: '/connections/active',
    roles: ROLES.PROVIDER_ROLES,
    isEnabled: ({ isProvider, providerProfileInfo, tags }) => {
      return (
        isProvider &&
        providerProfileInfo?.openToNewClients &&
        tags.some((tag) => tag.value === 'has_client_connection')
      )
    },
    showBadge: ({ showClientConnectionsBadge = false }) => showClientConnectionsBadge,
  },
  {
    key: 'community',
    text: 'Community',
    url: '/community',
    roles: ROLES.PROVIDER_ROLES,
    isEnabled: ({
      isProvider,
      hasSspProducts,
      hasFocusProducts,
      organizationSettings,
      organizationProductsStatus,
      providerProfileInfo,
      productPreferences,
    }) => {
      return canAccessCommunity({
        isProvider,
        hasSspProducts,
        hasFocusProducts,
        organizationSettings,
        organizationProductsStatus,
        providerProfileInfo,
        productPreferences,
      })
    },
  },
]

export const routes = menu
  .filter(({ key }) => !!key)
  .reduce(
    (res, item) => ({
      ...res,
      [item.key]: item,
    }),
    {}
  )
