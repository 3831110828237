import React, { useState } from 'react'
import {
  Grid,
  Button,
  SvgIcon,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  Collapse,
  Stack,
  Box,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { get } from 'lodash'
import useCart from '../utils/useCart'
import { useSelector } from 'react-redux'

export default function Product({ product }) {
  const { selectedAddOns = {} } = useSelector((state) => state.newPurchase.data)
  const quantity = selectedAddOns[product.SKU] || 0

  const imgSrc = get(product, 'Attachments[0]', '')
  const { SKU, Name, PriceTier1, Description } = product
  const { handleEditProducts } = useCart()
  const [expanded, setExpanded] = useState(false)

  // Split description into sentences
  const sentences = Description.split(/[.!?]\s/)
  const firstSentence = sentences[0] + '.'

  const handleExpandClick = () => setExpanded(!expanded)

  const onAdd = () => handleEditProducts(SKU, 'add')
  const onSubtract = () => quantity > 0 && handleEditProducts(SKU, 'subtract')

  const hasMoreThanOneSentence = sentences.length > 1

  return (
    <Grid item xs={12} py={3}>
      <Card
        sx={{
          display: 'flex',
          width: '100%',
          minHeight: 225,
          flexDirection: { xs: 'column', sm: 'row' },
        }}
        elevation={0}
        key={SKU}
      >
        <Stack direction="row">
          <CardMedia sx={{ height: 210, width: '50%' }} image={imgSrc} />
          <Box width="50%">
            <CardContent sx={{ py: 1 }}>
              <Typography variant="h5" gutterBottom>
                {Name}
              </Typography>
              <Divider />
              <Collapse in={expanded} collapsedSize={88}>
                <Typography variant="body2" sx={{ my: 1 }}>
                  {!expanded ? firstSentence : Description}
                </Typography>
              </Collapse>
              {/* Toggle button for expanding and collapsing */}
              {hasMoreThanOneSentence && (
                <Grid container justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
                  <Grid
                    item
                    sx={{ height: '1px', width: '33%', backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
                  />
                  <Grid item>
                    <Button onClick={handleExpandClick} aria-label="show more">
                      <Typography variant="button">{expanded ? 'Less' : 'More'}</Typography>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{ height: '1px', width: '33%', backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
                  />
                </Grid>
              )}
              <Grid container justifyContent="space-around" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="h5" sx={{ p: 2 }}>
                  ${PriceTier1}
                </Typography>
                <Grid container item alignItems="center" justifyContent="center" spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onSubtract}
                      sx={{ minWidth: 0 }}
                    >
                      <SvgIcon component={RemoveIcon} sx={{ height: '1.5rem' }} />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" sx={{ textAlign: 'center', px: 5 }}>
                      {quantity}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onAdd}
                      sx={{ minWidth: 0 }}
                    >
                      <SvgIcon component={AddIcon} sx={{ height: '1.5rem' }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
        </Stack>
      </Card>
    </Grid>
  )
}
