import React from 'react'
import { DashboardCard, Typography } from './DashboardCard2'

export default function IncompleteTrainingCard({ type = '', ...gridProps }) {
  const title =
    type === 'RRP'
      ? 'Complete your Preliminary RRP Training'
      : `Complete your Foundational ${type} Training`

  const content =
    type === 'RRP'
      ? `Completed RRP Training and an active subscription are required before you can deliver the RRP to your clients.`
      : `${type} certification and an active subscription are required before you can deliver the ${type} to your clients.`

  return (
    <DashboardCard
      {...gridProps}
      id={`complete-${type.toLowerCase()}-training`}
      title={title}
      dataTestLabel={`dashboard-card-enrolled-${type}-training`}
    >
      <Typography>{content}</Typography>
      <Typography pt={4}>
        Click on Academy in the left menu to access your {type} training.
      </Typography>
    </DashboardCard>
  )
}
