/**
 * Given a Date Object, format into this format:
 * - Jun 24, 2024
 */

const DATE_OPTIONS: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }

export const formatDate = (date: Date) => {
  return date.toLocaleString('en-US', DATE_OPTIONS)
}
